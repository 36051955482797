<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <apex-chart
                v-if="!isLoading"
                height="440px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const {
    VUE_APP_COLOR_PRIMARY: colorPrimary,
    VUE_APP_COLOR_ACCENT: colorAccent,
} = process.env

export default {
    name: 'NetworkChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            series: [
                {
                    name: this.$t('rssi'),
                    data: [],
                },
                {
                    name: this.$t('snr'),
                    data: [],
                },
                {
                    name: this.$t('sf'),
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                stroke: {
                    curve: 'straight',
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [colorPrimary, colorPrimary, colorAccent],
                fill: {
                    type: 'solid',
                    opacity: 0,
                },
                yaxis: [
                    {
                        max: 15,
                        min: -130,
                        seriesName: this.$t('rssi'),
                        title: {
                            text: `${this.$t('rssi')} ${this.$t(
                                'and'
                            )} ${this.$t('snr')}`,
                        },
                    },
                    {
                        max: 15,
                        min: -130,
                        seriesName: this.$t('snr'),
                        show: false,
                    },
                    {
                        max: 12,
                        min: 7,
                        opposite: true,
                        seriesName: this.$t('sf'),
                        title: {
                            text: this.$t('sf'),
                        },
                    },
                ],
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            const results = []

            let url =
                'measurements/?' +
                `timestamp_min=${encodeURIComponent(this.startDate.format())}` +
                `&timestamp_max=${encodeURIComponent(this.endDate.format())}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,network_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            this.series[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.rssi,
            ])
            this.series[1].data = results.map(item => [
                item.timestamp,
                item.network_data?.snr,
            ])
            this.series[2].data = results.map(item => [
                item.timestamp,
                item.network_data?.sf,
            ])
        },
    },
}
</script>

<i18n>
{
    "en": {
        "and": "and",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "de": {
        "and": "und",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "it": {
        "and": "e",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

@media only screen and (max-width: 1200px) {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}

@media only screen and (min-width: 1200px) {
    .datepicker {
        width: 20%;
        min-width: 350px;
    }
}
</style>
